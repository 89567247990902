import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RSignWebRoutes } from '../shared/constants/constants'
import { RSignLinks } from '../shared/constants/constants'
import { ToasterService } from '../common/toasterservice'
import { environment } from '../../environments/environment'
import { UserSessionService } from '../common/user-session.service'


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  rsignCompanyLogo: string = "../../assets/Images/RSignLogo.png";
  rpostCompanyLogo: string = "../../assets/Images/RPost-Button-White.png";
  login = environment.Root;
  home = environment.Root;
  send = environment.Root + RSignWebRoutes.Send;
  envelope = environment.Root + RSignWebRoutes.Envelope;
  templates = environment.Root + RSignWebRoutes.Templates;
  account = environment.Root + RSignWebRoutes.Account;
  company = environment.Root + RSignWebRoutes.Company;
  stats = environment.Root + RSignWebRoutes.Stats;
  settings = environment.Root + RSignWebRoutes.Settings;
  userprofile = environment.Root + RSignWebRoutes.Userprofile;

  tranining = RSignLinks.Tranining;
  help = RSignLinks.Help;
  rpost = RSignLinks.RPost;
  samesite: any;
  userName: any;
  appLang: any;
  langIcon: any;

  constructor(private router: Router, private toaster: ToasterService, private userSession: UserSessionService) { }

  ngOnInit(): void {
    this.samesite = localStorage.getItem('samesite');
    this.userName =  ""; //localStorage.getItem('UserName');
    this.appLang = this.userSession.getAppLanguage();   
    this.setLanguageIcon(this.appLang);
  }

  setLanguageIcon(language) {
    if (language == "en-us") { this.langIcon = "englishIcon"; }
    else if (language == "da-dk") { this.langIcon = "danishIcon"; }
    else if (language == "fr-fr") { this.langIcon = "frenchIcon"; }
    else if (language == "es-es") { this.langIcon = "spanishIcon"; }
    else if (language == "de-de") { this.langIcon = "germanIcon"; }
    else if (language == "pt-pt") { this.langIcon = "portugueseIcon"; }
    else if (language == "nl-nl") { this.langIcon = "dutchIcon"; }
    else if (language == "lv-lv") { this.langIcon = "latvianIcon"; }
    else if (language == "lt-lt") { this.langIcon = "lithuaniaIcon"; }
    else if (language == "pl-pl") { this.langIcon = "polishIcon"; }
    else if (language == "it-it") { this.langIcon = "italianIcon"; }
    else if (language == "hu-hu") { this.langIcon = "hungarianIcon"; }
    else if (language == "no-na") { this.langIcon = "norwegianIcon"; }
    else if (language == "co-es") { this.langIcon = "colombiaIcon"; }
    else if (language == "ro-ro") { this.langIcon = "romanianIcon"; }
    else { this.langIcon = "englishIcon"; }
  }

  logout() { 
    this.samesite = localStorage.getItem('samesite');
    localStorage.clear();       
    sessionStorage.clear();
    if (this.samesite == "false")
      this.toaster.showSuccessToaster("User Logout Successfully.", "Success");
    window.location.href = environment.Root;
  }
}
