import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { AppRoutes } from '../constants/constants'


@Injectable({ providedIn: 'root' })
export class TokenGuardService implements CanActivate {
    token: any;
    samesite: any;
    constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {       
        this.token = localStorage.getItem('userToken');
        if (this.token) {
            sessionStorage.setItem('userToken', this.token);           
            return true;
        }
        else {           
            this.router.navigateByUrl(AppRoutes.ErrorPage);
            return false;
        }
    }

}
