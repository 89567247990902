import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service'
import { AppRoutes } from '../shared/constants/constants'
import { Messages } from '../shared/constants/constants'
import { ToasterService } from '../common/toasterservice'
import { UserSessionService } from '../common/user-session.service'
import { LogService } from '../service/log.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})

export class HomepageComponent implements OnInit {
  samesite: any;
  token: any;
  companyId: any;
  email: any;
  showLoader: boolean = false;
  isError: boolean = false;
  page: any;

  constructor(private router: Router, private authenticationService: AuthenticationService,
    private toaster: ToasterService, private logger: LogService,
    private userSession: UserSessionService) {
  }

  ngOnInit(): void {
    this.showLoader = true;
    let mvcurl = decodeURIComponent(window.location.href);
    let paramsArray = this.getParamsFromUrl(mvcurl);
    if (!this.isError) {
      this.samesite = paramsArray['samesite'];
      this.token = paramsArray['rpk'];
      this.email = paramsArray['email'];
      this.page = paramsArray['page'];

      if (this.token != null && this.token != "") {
        var splitArry = this.token.split('.');
        if (splitArry.length == 3) {
          localStorage.setItem('authrnticationType', "jwt");
        }
        else {
          localStorage.setItem('authrnticationType', "auth");
        }
      }
      // if (this.page == 'translations'){localStorage.setItem('mvcURL', window.location.href);}      
      localStorage.setItem('samesite', paramsArray['samesite']);
      localStorage.setItem('userToken', paramsArray['rpk']);
      localStorage.setItem('email', paramsArray['email']);
      localStorage.setItem('userid', paramsArray['userid']);
      localStorage.setItem('companyId', paramsArray['companyID']);

      this.validateToken(this.token, this.email);
    }
    else {
      //if token is not validated then clear everything and redirect to error page  
      if (this.samesite == "false") { this.router.navigateByUrl(AppRoutes.Root); }
      else { this.router.navigateByUrl(AppRoutes.ErrorPage); }
    }
  }

  validateToken(token: any, email: any) {
    if (token && email) {
      this.showLoader = true;
      
    //  localStorage.setItem('companyId', "C2678266-FF76-49D3-AC02-EF69EF4E873F");      
      // this.userSession.setAppLanguage('en-us');  
      // this.showLoader = false;
      // if (this.samesite == "false")
      //   this.toaster.showSuccessToaster(Messages.SuccessAuthentication, "Success");
      // if (this.page == 'webhook')
      //   this.router.navigate(['/webhook']);
      // else
      //   this.router.navigate(['/translation']);


      let userTokenModel = { emailId: email };     

      this.authenticationService.validateToken(token, userTokenModel).subscribe(
        data => {
          if (data.IsError) {
            this.logger.error(data.IsError, data);
            if (this.samesite == "false") { this.router.navigateByUrl(AppRoutes.Root); }
            else { this.router.navigateByUrl(AppRoutes.ErrorPage); }
          }
          else {
            if (data) {
             // localStorage.setItem('companyId', data.companyID);
              localStorage.setItem('UserName', data.firstName + " " + data.lastName);
              localStorage.setItem('companyName', data.company);
              localStorage.setItem('userId', data.userID);
              this.userSession.setuserData(data);

              if (!data.languageCode) { data.languageCode = 'en-us'; } //default lang
              this.userSession.setAppLanguage(data.languageCode);   //used for language transaltion

              this.showLoader = false;
              if (this.samesite == "false")
                this.toaster.showSuccessToaster(Messages.SuccessAuthentication, "Success");
              if (this.page == 'webhook')
                this.router.navigate(['/webhook']);
              else
                this.router.navigate(['/translation']);
            }
            else {
              localStorage.setItem('userData', '');
              if (this.samesite == "false")
                this.toaster.showErrorToaster(Messages.FailedAuthentication, "Attention");
              if (this.samesite == "false") { this.router.navigateByUrl(AppRoutes.Root); }
              else { this.router.navigateByUrl(AppRoutes.ErrorPage); }
            }
          }
        },
        error => {
          this.logger.error("true", error);
          if (this.samesite == "false")
            this.toaster.showErrorToaster(Messages.FailedAuthentication, "Attention");
          if (this.samesite == "false") { this.router.navigateByUrl(AppRoutes.Root); }
          else { this.router.navigateByUrl(AppRoutes.ErrorPage); }
        });
    }
    else {
      this.logger.log("token is not exists", "token is not exists");
      if (this.samesite == "false") { this.router.navigateByUrl(AppRoutes.Root); }
      else { this.router.navigateByUrl(AppRoutes.ErrorPage); }
    }
  }

  getParamsFromUrl(url) {
    this.showLoader = true;
    let obj = {};
    try {
      url = decodeURI(url);
      if (typeof url === 'string') {
        let params = url.split('?');
        let eachParamsArr = params[1].split('&');
        if (eachParamsArr && eachParamsArr.length) {
          eachParamsArr.map(param => {
            let keyValuePair = param.split('=')
            let key = keyValuePair[0];
            let value = keyValuePair[1];
            obj[key] = value;
          })
        }
        this.showLoader = false;
        return obj;
      }
    }
    catch (error) {
      console.log(Messages.ParametersFailed);
      this.showLoader = false;
      this.isError = true;
      return obj;
    }
  }
}



