import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Helper } from '../common/class/helper';
import { Router } from '@angular/router';
import { ApiRoutes } from '../shared/constants/constants'
import { environment } from '../../environments/environment'
import { UserTokenModel } from '../class/userprofile'

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  baseUrl: string | undefined;
  routeUrl: string | undefined;

  constructor(private http: HttpClient, private router: Router, private helper: Helper) {
    this.baseUrl = environment.RootApi;
  }

  validateToken(token: any, userTokenModel: any) {
    var authrnticationType = localStorage.getItem('authrnticationType');
    if (authrnticationType == "jwt") {
      this.routeUrl = this.baseUrl + ApiRoutes.ValidateJWTToken;
    }
    else {
      this.routeUrl = this.baseUrl + ApiRoutes.ValidateToken;
    }

    // let headers = new HttpHeaders({
    //   'AuthToken': token,
    //   'Content-Type': 'application/json;charset=UTF-8'     
    // });

    let headers = new HttpHeaders()
    headers = headers.append('AuthToken', token);   
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Accept', 'application/json');    

    let formData: UserTokenModel = { EmailId: userTokenModel.emailId };
    return this.http.post<any>(`${this.routeUrl}`, formData, { headers: headers }).pipe(catchError(this.helper.handleError));
  }
}
