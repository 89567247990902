import { Component, OnInit, Injectable } from '@angular/core';


@Injectable({ providedIn: 'root' })
@Component({
  selector: 'app-loadingicon',
  templateUrl: './loadingicon.component.html',
  styleUrls: ['./loadingicon.component.scss']
})
export class LoadingiconComponent implements OnInit {
  loadingImage:string= "../../assets/Images/rsign-loader1.gif";
  constructor() { }

  ngOnInit(): void {
  }
}
