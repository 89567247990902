import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RSignWebRoutes } from '../shared/constants/constants'
import { RSignLinks } from '../shared/constants/constants'
import { Messages } from '../shared/constants/constants'
import { environment } from '../../environments/environment'

@Component({
  selector: 'app-errorpage',
  templateUrl: './errorpage.component.html',
  styleUrls: ['./errorpage.component.scss']
})
export class ErrorpageComponent implements OnInit {
  errorDescription: string;  
  samesite: any;

  login = environment.Root;
  training = RSignLinks.Tranining;
  unAuthorizedUser = Messages.UnAuthorizedUser;
  pageNotFound = Messages.PageNotFound;
  somethingWentWrong = Messages.SomethingWentWrong;

  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe(params => this.errorDescription = (params.errorDesc));
  }

  ngOnInit(): void {
    this.samesite = localStorage.getItem('samesite');
  }

}
