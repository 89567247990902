export const AppRoutes = {
    Root: '/',
    ErrorPage:'/error/un-authorized-user',
    PageNotFound: '/error/page-not-found',
    Content: '/content',
    SomethingWentWrong: '/error/something-went-wrong'    
};

export const ApiRoutes = {   
    ValidateToken:'/api/Account/ValidateToken',
    GetWebhookEventMaster:'/api/WebhookEventmaster/GetWebhookEventMasterDetails',
    GetAllWebhookEvent:'/api/WebhookEvents/GetAllWebhookEvents',
    PostWebhookEvent:'/api/WebhookEvents/AddWebhookCustomerEvent',
    GetWebhookParameters:'/api/WebhookParameters/GetWebhookParametersDetails',  
    GetWebhookEventParamMapping:'/api/WebhookParameters/GetWebhookEventParamMapping',
    AngularLogInfo:'/api/AngularLog/LogInfo',
    ValidateJWTToken:'/api/Account/ValidateTokenV2'
};

export const RSignWebRoutes = {   
    Send:'/Envelope/EnvelopeIndex',
    Envelope:'/DocumentPackage',
    Templates:'/Template',
    Account:'/ManageAdmin/ManageUsers',
    Company:'/Company/Companies',
    Stats:'/Home/Stats',
    Settings:'/Settings',
    Userprofile:'/Settings/UserDetails',
    Translations:'/Translations/Index',      
};

export const RSignLinks = {  
    Tranining:'http://www.rsign.com/support#training-webinars-videos',   
    Help:'https://support.rpost.com/hc/en-us/articles/360038143733-RSign-Settings-Tab',
    RPost:'https://www.rpost.com'
};

export const FooterRoutes = {
    LegalNotices:'https://rpost.com/legal-notices/terms-and-conditions/',
    Technologies:'https://www.rpost.com/technologies/',
    Patented:'https://rpost.com/legal-notices/legal-and-patent-notice'   
};

export const RSignDefault = {
    SecretKeyText : "RPWH-",
    PageSize : 5,
    DefaultPageSize : 5
}

export const Messages = {
    UnAuthorizedUser : 'Unauthorized User',
    SuccessAuthentication : 'User successfully authenticated.',
    FailedAuthentication : 'User is not authenticated.',
    PageNotFound : 'The Requested page is not found.',
    SomethingWentWrong : 'The service is down. Please try again later.',
    UpdatedMsg: "Webhook updated successfully.",
    AddedMsg: "Webhook added successfully.",
    ParametersFailed: "Getting parameters from URL is failed.",
    RecordAlreadyExists: "Record already exists."     
}

export const Regex = {   
    GUID: /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
}

export const UserRole = {
    LanguageTranslator : "LanguageTranslator",
    LanguageAdmin : 'LanguageAdmin'
}
export const LanguageApiRoutes = {   
  
    GetLanguageList:'/api/LanguageTranslation/GetLanguageTranslation',
    GetFilteredTranslation:'/api/LanguageTranslation/GetFilteredTranslation',
    SaveLanguageTranslation:'/api/LanguageTranslation/SaveLanguageTranslation',
    UploadLanguageDocument:'/api/LanguageTranslation/UploadLanguageTranslationDocument',
    ViewLanguageTranslationDocument:'/api/LanguageTranslation/ViewLanguageTranslationDocument',
    DisplayLanguageTranslationDocument:'/api/LanguageTranslation/DisplayLanguageTranslationDocument'
};

