import { Injectable, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class ToasterService implements OnInit {
    constructor(private toastr: ToastrService) { }
    ngOnInit() { }

    showSuccessToaster(message, title) {
        this.toastr.success(message, title)
    }
    showErrorToaster(message, title) {
        this.toastr.error(message, title)
    } 
    showWarnToaster(message, title) {
        this.toastr.warning(message, title)
    }  
}
