import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, filter, debounceTime, catchError, tap, delay } from 'rxjs/operators';
import { Helper } from '../common/class/helper';
import { Router } from '@angular/router';
import { WebhookEventMaster } from '../class/webhook'
import { WebhookEvents } from '../class/webhook'
import { WebhookEventParametersMapping } from '../class/webhook'
import { ApiRoutes } from '../shared/constants/constants'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class WebhookService {
  baseUrl: string | undefined;
  routeUrl: string | undefined;
  token: any;
  companyId: any;
  secretKey: any;

  constructor(private http: HttpClient, private router: Router, private helper:Helper) {
    this.baseUrl = environment.RootApi;
    this.token = localStorage.getItem('userToken');
    this.companyId = localStorage.getItem('companyId');
  }

  get requestOptions() {
    return {
      headers: new HttpHeaders({
        'AuthToken': this.token,       
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json;'
      })
    };
  } 

  getEventParameters(eventCode: string) {
    this.routeUrl = this.baseUrl + ApiRoutes.GetWebhookEventParamMapping + "?eventCode=" + eventCode;
    return this.http.get<WebhookEventParametersMapping[]>(`${this.routeUrl}`, { ...this.requestOptions }).pipe(catchError(this.helper.handleError));
  }

  getEventlist() {
    this.routeUrl = this.baseUrl + ApiRoutes.GetAllWebhookEvent + "/" + this.companyId
    return this.http.get<WebhookEvents[]>(`${this.routeUrl}`, { ...this.requestOptions }).pipe(catchError(this.helper.handleError));
  }

  getEventMaster() {
    this.routeUrl = this.baseUrl + ApiRoutes.GetWebhookEventMaster;
    return this.http.get<WebhookEventMaster[]>(`${this.routeUrl}`, { ...this.requestOptions }).pipe(catchError(this.helper.handleError));
  }

  saveWebhookEvent(postdata) {
    const formData: FormData = postdata;
    this.routeUrl = this.baseUrl + ApiRoutes.PostWebhookEvent;   
    return this.http.post<any>(`${this.routeUrl}`, formData, { ...this.requestOptions  }).pipe(catchError(this.helper.handleError));
  }
}
