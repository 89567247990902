import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { Helper } from '../../common/class/helper';
import { ToasterService } from '../../common/toasterservice'
import { LogService } from '../../service/log.service';
import { LangTranslationService } from '../../service/lang-translation.service';
import { FileToUpload, ImageModel } from '../../class/FileToUpload';
import { DropDownOptionDetails, LanguageKeyPageName, LanguageTranslatedData, LanguageTranslationList } from '../../class/languageTranslation';
import { HttpEventType } from '@angular/common/http';
import { UserRole } from '../../shared/constants/constants';
import { DomSanitizer } from '@angular/platform-browser';
import { AppRoutes } from '../../shared/constants/constants'
import { IfStmt } from '@angular/compiler';

@Component({
  selector: 'app-lang-translation',
  templateUrl: './lang-translation.component.html',
  styleUrls: ['./lang-translation.component.scss']
})
export class LangTranslationComponent implements OnInit {
  samesite: any = 'true';
  showLoader: boolean = false;
  searchForm: FormGroup;
  editForm: FormGroup;
  controlArray: FormArray;
  LanguageList: DropDownOptionDetails[];
  LanguageFilterList: DropDownOptionDetails[];
  TranslationList: LanguageTranslationList[];
  filteredEventList: LanguageTranslationList[];
  LanguagePageNameList: LanguageKeyPageName[];
  RolesList: string[];
  email: any;
  slPageSize = 25;
  defaultPageSize = 25;
  totalRecords: any;
  filterType: string = 'Select';
  languageCode: string = 'en-us';
  translationType: string = 'Select';
  p: number = 1;
  submitted = false;
  displayRows: boolean = false;
  sortingName: string;
  isDesc: boolean;
  translationToSave: LanguageTranslatedData[];
  translationToSaveTemporary: LanguageTranslatedData[];
  file: File = null;
  theFile: any = null;
  messages: string[] = [];
  isLanguageAdmin: boolean = false;
  isLanguageTranslator: boolean = false;
  imageData: ImageModel[];
  displayStyle = "none";
  helpModalDisplayStyle = "none";
  toggleLayer: boolean = false;
  searchText: string = '';
  slTranslationType: string = "0";
  helpImage: string = "../../../assets/Images/helpicon.png";

  @ViewChild('file') fileUpload: ElementRef;
  constructor(private langService: LangTranslationService, private formBuilder: FormBuilder, private helper: Helper,
    private router: Router, private sanitizer: DomSanitizer,
    private toaster: ToasterService, private logger: LogService) {
    this.email = localStorage.getItem('email');
    this.editForm = this.formBuilder.group({ controlArray: this.formBuilder.array([]) })
  }

  ngOnInit(): void {
    this.getLanguageList(this.p, this.defaultPageSize);
    this.searchForm = this.formBuilder.group({ txtSearch: new FormControl('', Validators.required) });
    this.translationToSaveTemporary = [];
  }

  getLanguageList(pageIndex, pageSize) {
    this.showLoader = true;
    this.toggleLayer = true;

    this.langService.getLanguagelist(pageIndex, pageSize).subscribe(
      data => {
        if (data.IsError) {
          this.logger.error(data.IsError, data);
          this.toaster.showErrorToaster(data.Error, "Attention");
          this.showLoader = false;
          this.toggleLayer = false;
          if (this.samesite == "false") { this.router.navigateByUrl(AppRoutes.Root); }
          else {
            // window.location.href = localStorage.getItem('mvcURL');
            this.router.navigateByUrl(AppRoutes.ErrorPage);
          }
        }
        else {
          this.LanguageList = data.languageDetails;
          this.LanguageFilterList = data.filterDetails;
          this.RolesList = data.rolesList;
          if (this.RolesList.length > 0) {
            this.isLanguageAdmin = this.RolesList.filter(x => x == UserRole.LanguageAdmin).length > 0;
            this.isLanguageTranslator = this.RolesList.filter(x => x == UserRole.LanguageTranslator).length > 0;
          }
          this.TranslationList = data.languageTranslationList;
          this.LanguagePageNameList = data.pageNameList;
          this.totalRecords = data.totalRecords;
          this.translationType = '0';
          this.setTranslationFormArray();
          this.filteredEventList = this.TranslationList;
          if (data.languageTranslationList.length > 0)
            this.defaultPageSize = this.slPageSize;
          this.showLoader = false;
          this.toggleLayer = false;         
        }
      },
      error => {
        this.logger.log("true", error);
        this.showLoader = false;
        this.toggleLayer = false;
      });
  }

  get translationRows() {
    return this.editForm.get("controlArray") as FormArray;
  }

  setTranslationFormArray() {
    let arr = this.editForm.controls.controlArray as FormArray;
    arr.controls = [];
    this.TranslationList.forEach(x => {
      arr.push(this.formBuilder.group({
        comments: x.comments,
        keyValue: x.keyValue,
        pageName: x.pageName,
        id: x.id,
        keyName: x.keyName,
        keyDescription: x.keyDescription,
        status: x.status,
        languageCode: x.languageCode,
        resourceKeyId: x.resourceKeyId
      }))
    })
  }

  changePageSize(e: any, i: number) {
    this.slPageSize = e.target.value;
    this.p = 1;
    var code = this.languageCode;
    var filter = this.filterType;
    this.translationToSaveTemporary = [];
    this.getTranslationList(code, filter);
  }

  changeLanguage(e, Islanguage: boolean) {
    this.showLoader = true;
    this.toggleLayer = true;
    var code: string = 'en-us';
    var filter: string = 'Select';
    if (Islanguage === true) {
      code = e.target.value;
      filter = this.filterType;
    }
    else {
      code = this.languageCode;
      filter = e.target.value;
    }
    this.languageCode = code;
    this.filterType = filter;
    this.p = 1;
    this.slPageSize = 25;
    this.translationToSaveTemporary = [];
    this.getTranslationList(code, filter);
  }

  changeTranslationType(e) {
    this.showLoader = true;
    this.toggleLayer = true;
    this.slTranslationType = e.target.value;
    this.p = 1;
    this.slPageSize = 25;
    this.translationToSaveTemporary = [];
    this.getTranslationList(this.languageCode, this.filterType);
  }

  searchEvent(input) {
    this.submitted = true;
    this.showLoader = true;
    var code = this.languageCode;
    var filter = this.filterType;
    this.p = 1;
    this.slPageSize = 25;
    this.searchText = input.value.txtSearch;
    this.translationToSaveTemporary = [];
    this.getTranslationList(code, filter);
  }

  filterItem(input) {
    //this.showLoader = true;
    // this.submitted = false;
    if (!input.value.txtSearch) {
      this.searchEvent(input);
    }
  }

  searchFilter(input, type: string) {
    const filtername: string = this.filterType;
    if (type == "search") {
      this.TranslationList = this.filteredEventList.filter(function (item) {
        return (item.keyDescription.toLowerCase() === input.value.txtSearch.toLowerCase());
      });
    }
    else if (type == "filter") {
      this.TranslationList = this.filteredEventList.filter(function (item) {
        return ((item.keyDescription.toLowerCase().indexOf(input.value.txtSearch.toLowerCase()) === 0));
      });
    }
    this.setTranslationFormArray();
    if (this.TranslationList.length > 0)
      this.defaultPageSize = this.slPageSize
    this.totalRecords = this.TranslationList.length;
    this.p = 1;
    this.showLoader = false;
  }

  get s() { return this.searchForm.controls; }

  onSubmit(isSubmit: boolean) {
    if (this.translationToSave == undefined) {
      this.translationToSave = [];
    }
    let data = this.getDirtyValues(this.editForm);
    let status = isSubmit ? "Submitted" : "Work In Progress";
    if ((Object.keys(data).length !== 0)) {
      if (data.hasOwnProperty('controlArray')) {
        Object.keys(data).forEach(key => {
          for (let obj in data[key]) {
            const value = data[key][obj];
            value.status = status;
            value.translationReq = '';
            if (value.hasOwnProperty('comments')) {
              value.translationReq = 'c';
            }
            if (value.hasOwnProperty('keyValue')) {
              value.translationReq = value.translationReq + 'v';
            }
            if (value.hasOwnProperty('pageName')) {
              value.translationReq = value.translationReq + 'p';
            }
            this.translationToSave.push(value);
          }
        });
      }

      if (this.translationToSave.length > 0) {
        if (status == 'Submitted') { this.translationToSave.forEach(element => { element.status = status; }); }
        this.saveTranslation(status);
      }

      if (this.translationToSaveTemporary.length > 0) {
        this.translationToSaveTemporary.forEach(element => {
          let languageTranslatedDataValue = { status: '', translationReq: '', id: '', pageName: '', keyValue: '', comments: '' };

          languageTranslatedDataValue.translationReq = '';
          if (element.hasOwnProperty('comments')) {
            languageTranslatedDataValue.translationReq = 'c';
          }
          if (element.hasOwnProperty('keyValue')) {
            languageTranslatedDataValue.translationReq = languageTranslatedDataValue.translationReq + 'v';
          }
          if (element.hasOwnProperty('pageName')) {
            languageTranslatedDataValue.translationReq = languageTranslatedDataValue.translationReq + 'p';
          }

          languageTranslatedDataValue.id = element.id;
          languageTranslatedDataValue.pageName = element.pageName;
          languageTranslatedDataValue.keyValue = element.keyValue;
          languageTranslatedDataValue.comments = element.comments;
          languageTranslatedDataValue.status = status;

          this.translationToSave.push(languageTranslatedDataValue);
        });
        this.saveTranslation(status);
      }
    }

    if (status == "Submitted") {
      this.saveTranslation(status);
    }
  }

  saveTranslation(status: string) {
    this.showLoader = true;
    this.toggleLayer = true;
    this.langService.saveTranslationList(this.translationToSave, status).subscribe(data => {
      this.getTranslationList(this.languageCode, this.filterType);
      this.showLoader = false;
      this.toggleLayer = false;
      if (status == 'Submitted') {
        this.translationToSave = [];
        this.translationToSaveTemporary = [];
      }
      if (status == 'Work In Progress') {
        this.translationToSaveTemporary = [];
      }

      if (status == "Work In Progress") { this.toaster.showSuccessToaster("Translations saved successfully", "Success"); }
      else if (status == "Submitted") { this.toaster.showSuccessToaster("Translations submitted successfully", "Success"); }
    },
      error => {
        this.logger.log("true", error);
        this.showLoader = false;
        this.toggleLayer = false;
        if (status == "Work In Progress") { this.toaster.showErrorToaster("Translations are not saved", "Attention"); }
        else if (status == "Submitted") { this.toaster.showErrorToaster("Translations are not submitted", "Attention"); }
      }
    )
  }

  private getTranslationList(code: string, filter: string) {
    this.showLoader = true;
    this.toggleLayer = true;
    this.langService.getTranslationListByLanguage(code, filter, this.p, this.slPageSize, this.searchText, this.slTranslationType).subscribe(data => {
      this.TranslationList = data.languageTranslationList;
      this.totalRecords = data.totalRecords;

      if (this.TranslationList.length > 0 && this.translationToSaveTemporary.length > 0) {
        this.translationToSaveTemporary.forEach(element => {
          let objIndex = this.TranslationList.findIndex((e => e.id === element.id));
          if (objIndex > -1) {
            this.TranslationList[objIndex].keyValue = element.keyValue != undefined ? element.keyValue : this.TranslationList[objIndex].keyValue;
            this.TranslationList[objIndex].pageName = element.pageName != undefined ? element.pageName : this.TranslationList[objIndex].pageName;
            this.TranslationList[objIndex].comments = element.comments != undefined ? element.comments : this.TranslationList[objIndex].comments;

            //Update these records- used for on save with pagignation
            element.keyValue = element.keyValue != undefined ? element.keyValue : this.TranslationList[objIndex].keyValue;
            element.pageName = element.pageName != undefined ? element.pageName : this.TranslationList[objIndex].pageName;
            element.comments = element.comments != undefined ? element.comments : this.TranslationList[objIndex].comments;
          }
        });
      }

      this.filteredEventList = this.TranslationList;
      this.setTranslationFormArray();
      if (this.TranslationList.length > 0)
        this.defaultPageSize = this.slPageSize
      this.showLoader = false;
      this.toggleLayer = false;    
    },
      error => {
        this.logger.log("true", error);
        this.showLoader = false;
        this.toggleLayer = false;
      });
  }

  onSaveTranslation(input) {
    let data = this.getDirtyValues(input);
  }

  getDirtyValues(form: any) {
    let dirtyValues = {};

    Object.keys(form.controls).forEach(key => {
      let currentControl = form.controls[key];
      let idControl = form.controls['id'];
      let resourceKeyId = form.controls['resourceKeyId'];
      let keyTranslation = form.controls['keyValue'];
      let pagename = form.controls['pageName'];
      if (currentControl.dirty) {
        if (currentControl.controls) {
          dirtyValues[key] = this.getDirtyValues(currentControl);
        }
        else {
          dirtyValues[key] = currentControl.value;
          dirtyValues['id'] = idControl.value;
          dirtyValues['resourceKeyId'] = resourceKeyId.value;
          dirtyValues['keyValue'] = keyTranslation.value;
          dirtyValues['pageName'] = pagename.value;
        }
      }
    });

    return dirtyValues;
  }

  sort(name: string) {
    this.isDesc = (name && this.sortingName !== name) ? true : !this.isDesc; // default to descending, otherwise toggle
    this.sortingName = name;
    this.helper.sortTable(this.isDesc, this.TranslationList, name);
    return this.setTranslationFormArray();
  }

  onFilechange(event: any) {
    this.theFile = event.target.files[0];
    const files = event.target.files[0];
    const id = event.target.id;
    if (files.length === 0) {
      return;
    }
    this.showLoader = true;
    let filesToUpload: File[] = files;
    if (this.theFile.size > 26214400) {
      this.toaster.showErrorToaster("File should be less than 25 mb size", "Attention");
      this.showLoader = false;
      return;
    }

    this.uploadFile(id);
  }

  uploadFile(id: string): void {
    this.readAndUploadFile(this.theFile, id);
  }

  private readAndUploadFile(theFile: any, resourceKeyId: string) {
    this.showLoader = true;
    this.toggleLayer = true;
    let file = new FileToUpload();
    var ext = theFile.name.substr(theFile.name.lastIndexOf('.') + 1);

    file.fileName = theFile.name;
    file.fileSize = String(theFile.size);
    file.fileType = theFile.type;
    file.resourceKeyId = resourceKeyId;

    let reader = new FileReader();

    reader.onload = () => {
      file.fileAsBase64 = reader.result.toString();
      this.langService.uploadFile(file).subscribe(resp => {
        this.fileUpload.nativeElement.value = null;
        this.showLoader = false; this.toggleLayer = false;
        this.toaster.showSuccessToaster("Image uploaded successfully", "Success");
      },
        error => {
          this.fileUpload.nativeElement.value = null;
          this.logger.log("true", error); this.showLoader = false;
          this.toaster.showErrorToaster("Image upload failed", "Attention");
          this.showLoader = false; this.toggleLayer = false;
        });
    }
    reader.readAsDataURL(theFile);
  }

  onViewDocument(event: any, index: any) {
    this.showLoader = true;
    const item: any = this.translationRows.at(index);
    var resourceKeyId = item.controls.resourceKeyId.value

    this.langService.downloadFile(resourceKeyId).subscribe(data => {

      if (data) {

        if (data.type == HttpEventType.Response) {
          const downloadedFile = new Blob([data['body']], { type: data['body']['type'] });
          const a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          a.download = item.controls.resourceKeyId.value;
          a.href = URL.createObjectURL(downloadedFile);
          a.target = '_blank';
          a.click();
          document.body.removeChild(a);
          this.showLoader = false;
        }
      }
      else {
        this.showLoader = false;
      }
    },
      error => {
        this.fileUpload.nativeElement.value = null;
        this.logger.log("true", error); this.showLoader = false;
      });
  }

  onView(event: any, index: any) {
    this.showLoader = true;
    this.toggleLayer = true;
    const item: any = this.translationRows.at(index);
    var resourceKeyId = item.controls.resourceKeyId.value;

    this.langService.DisplayFile(resourceKeyId).subscribe(data => {
      if (data.length > 0) {
        this.imageData = data;
        for (let i = 0; i < data.length; i++) {
          var name = data[i].name;
          // name.substr(name.lastIndexOf('_') + 1);
          var name = name.substring(0, name.indexOf('_'));
          let objectURL = 'data:image/png;base64,' + data[i].data;
          this.imageData[i].Url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.imageData[i].name = name;
        }
        this.openPopup();
        this.showLoader = false;
        this.toggleLayer = false;
      }
      else {
        this.showLoader = false;
        this.toggleLayer = false;
        this.toaster.showErrorToaster("File is not found.", "Attention");
      }
    },
      error => {
        this.fileUpload.nativeElement.value = null;
        this.logger.log("true", error);
        this.showLoader = false; this.toggleLayer = false;
        this.toaster.showErrorToaster("Unable to view the document", "Attention");
      });
  }

  openPopup() {
    this.displayStyle = "block";
  }

  closePopup() {
    this.displayStyle = "none";
  }

  pageChangeEvent(page) {
    this.p = page;
    var code = this.languageCode;
    var filter = this.filterType;
    this.savetranslationToTemporaryArray();
    this.getTranslationList(code, filter);
  }

  openHelpModalPopup() {
    this.helpModalDisplayStyle = "block";
  }

  closeHelpModalPopup() {
    this.helpModalDisplayStyle = "none";
  }

  savetranslationToTemporaryArray() {
    let data = this.getDirtyValues(this.editForm);
    if ((Object.keys(data).length !== 0)) {
      if (data.hasOwnProperty('controlArray')) {
        Object.keys(data)
          .forEach(key => {
            for (let obj in data[key]) {
              const value = data[key][obj];
              value.status = '';
              value.translationReq = '';
              if (value.hasOwnProperty('comments')) {
                value.translationReq = 'c';
              }
              if (value.hasOwnProperty('keyValue')) {
                value.translationReq = value.translationReq + 'v';
              }
              if (value.hasOwnProperty('pageName')) {
                value.translationReq = value.translationReq + 'p';
              }
              this.translationToSaveTemporary.push(value);
            }
          });
      }
    }
  }

  getNonDirtyValues(form: any) {
    let nonDirtyValues = {};
    Object.keys(form.controls).forEach(key => {
      let currentControl = form.controls[key];
      if (!currentControl.dirty) { nonDirtyValues[key] = currentControl.value; }
    });
    return nonDirtyValues;
  }

}
