<div class="header" id="webhookHeader">
    <div class="d-flex justify-content-between align-items-center logo-wrapper">
        <a href="{{login}}" target="_blank">
            <img class="rsign-logo img-responsive" [src]="rsignCompanyLogo">
        </a>
        <!-- <div class='header-text'>  </div> -->
        <a href="{{rpost}}" target="_blank" class="customer-logo">
            <img class="rsign-logo img-responsive" [src]="rpostCompanyLogo">
        </a>
    </div>
    <!-- primary header -->
    <div class="primary-header">
        <nav class="nav">
            <a class="nav-link" target="_blank" href="{{login}}"><span class="home"></span>{{ "Navebar.Home" | translate }} </a>
            <a class="nav-link" target="_blank" href="{{send}}"><span class="send"></span>{{ "Navebar.Send" | translate }} </a>
            <a class="nav-link" target="_blank" href="{{envelope}}"><span class="manage"></span>{{ "Navebar.Envelopes" | translate }} </a>
            <a class="nav-link" target="_blank" href="{{templates}}"><span
                    class="managetemplate"></span>{{ "Navebar.Templates" | translate }} </a>
            <a class="nav-link" target="_blank" href="{{account}}"><span class="manageAdmin"></span>{{ "Navebar.Account" | translate }} </a>

            <a *ngIf="samesite === 'false'" target="_blank" class="nav-link active" href="{{settings}}"><span class="settingsAdmin"></span>{{ "Navebar.Settings" | translate }} </a>
            <a *ngIf="samesite === 'true'" class="nav-link active" href="/webhook"><span class="settingsAdmin"></span>{{ "Navebar.Settings" | translate }} </a>
        </nav>
        <span class="d-sm-block d-md-none mob-user-logout">...</span>
        <div class="user-logout">
            {{ "Profile.Hello" | translate }},
            <a title="Click here to edit profile" id="alink" class="profEdit" target="_blank" href="{{userprofile}}">
                <span><b>{{userName}}</b></span>
                <span class="langFlagIcon" [ngClass]="langIcon" id="divFlagIcon"></span>
            </a>
            <a title="Home Help" target="_blank" href="{{help}}"><i class="icofont-question-circle"></i></a>
            <!-- <a (click)="logout() "><i class="icofont-power "></i></a> -->
            <span title="Logout" class="logoutbtn active">
                <a id="logouttap" (click)="logout()" class="logout rsignuserlogout" style="z-index:500">{{ "Profile.SignOut" | translate }}</a>
            </span>
        </div>
    </div>
</div>