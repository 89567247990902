import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment'
import { RSignLinks } from '../shared/constants/constants'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  rsignCompanyLogo: string = "../../assets/Images/RSignLogo.png";
  rpostCompanyLogo: string = "../../assets/Images/RPost-Button-White.png";
  login = environment.Root;
  rpost = RSignLinks.RPost;
  constructor() { }

  ngOnInit(): void {
  }

}
