import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Helper } from '../../common/class/helper';
import { WebhookService } from '../../service/webhook.service'
import { WebhookEvents } from '../../class/webhook'
import { AppRoutes } from '../../shared/constants/constants'
import { ToasterService } from '../../common/toasterservice'
import { RSignDefault, Regex } from '../../shared/constants/constants'
import { Messages } from '../../shared/constants/constants'

import { ClipboardService } from 'ngx-clipboard';
import { LogService } from '../../service/log.service';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'app-webhook',
  templateUrl: './webhook.component.html',
  styleUrls: ['./webhook.component.scss']
})
export class WebhookComponent implements OnInit {
  company: string = "";// localStorage.getItem('companyName');
  eventStatusList: any;
  eventParametersList: any;
  eventList: any;
  filteredEventList: any;
  url: any;
  addEditForm: FormGroup;
  searchForm: FormGroup;
  showError: boolean = false;
  showEventError: boolean = false;
  showUrlError: boolean = false;
  submitted = false;
  slEventParameters = [];
  activeTab: any;
  isDesc: boolean;
  isValidUUID: boolean;
  showSecretKeyNotValidError: boolean;
  showSecretKeyRequired: boolean;
  showSecretKeyHeaderRequired: boolean;
  sortingName: string;
  eventsCount: boolean;
  showLoader: boolean = false;
  default: string = '-1';
  token: any;
  eventParameterNames: [];
  isEdited: boolean;
  samesite: any;
  companyId: any;
  email: any;
  p: number = 1;
  toggleLayer: boolean = false;
  slPageSize = RSignDefault.PageSize;
  defaultPageSize = RSignDefault.DefaultPageSize;
  showDivParameters: boolean = false;
  showEyeIcon: boolean = false;

  webhookEventParameters = [];
  postdata = {
    webhookEvents: {},
    webhookEventParameters: []
  }

  constructor(private formBuilder: FormBuilder, private helper: Helper, private webhookService: WebhookService, private router: Router,
    private toaster: ToasterService, private clipboardApi: ClipboardService, private logger: LogService) {
    this.token = localStorage.getItem('userToken');
    this.samesite = localStorage.getItem('samesite');
    this.email = localStorage.getItem('email');

    if (!this.token || !this.samesite || this.token == "undefined" || this.samesite == "undefined") {
      if (this.samesite == "false") { this.router.navigateByUrl(AppRoutes.Root); }
      else { this.router.navigateByUrl(AppRoutes.ErrorPage); }
    }

    const reg = /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    this.addEditForm = this.formBuilder.group({
      eventStatus: new FormControl('', Validators.required),
      rpwhSecretKey: ['', [Validators.required]],
      secretKeyHeaderName: ['', [Validators.required]],
      url: ['', [Validators.required, Validators.pattern(reg)]],
      status: new FormControl(''),
    });

    this.searchForm = this.formBuilder.group({ txtSearch: new FormControl('', Validators.required) });
  }

  ngOnInit(): void {
    this.showLoader = true;
    this.setAppLanguage();
    this.eventList = [];
    this.eventList = this.getEventLists();
    this.activeTab = 'webhook';
  }

  get f() { return this.addEditForm.controls; }

  get s() { return this.searchForm.controls; }

  clickWebhookTab(activeTab) {
    this.showLoader = true;
    this.toggleLayer = true;
    this.activeTab = activeTab;
    this.submitted = false;
    this.eventParameterNames = [];
    this.slEventParameters = [];
    this.isEdited = false;
    // this.searchForm.reset();
    // this.eventList = this.getEventLists();    
    localStorage.setItem('selectedEventDetails', '');
    this.toggleLayer = false;
    this.showLoader = false;
  }

  clickAddEditTab(activeTab) {
    this.addEditForm.reset();
    this.eventParametersList = [];
    this.eventStatusList = this.getEventMasterList("add");
    this.activeTab = activeTab;
    this.submitted = false;
    this.addEditForm.controls['eventStatus'].setValue(this.default);
    this.addEditForm.controls['status'].setValue(1);
    //Guid generation
    this.showEyeIcon = true;
    let secretKeyText = RSignDefault.SecretKeyText + this.generateUuid();
    this.addEditForm.controls['rpwhSecretKey'].setValue(secretKeyText);

    this.slEventParameters = [];
    this.isEdited = false;
    localStorage.setItem('selectedEventDetails', '');
    this.showDivParameters = false;
  }

  getEventLists() {
    this.showLoader = true;
    this.toggleLayer = true;
    let search = this.searchForm.value.txtSearch;
    this.webhookService.getEventlist().subscribe(
      data => {
        if (data.IsError) {
          this.logger.error(data.IsError, data);
          if (this.samesite == "false") { this.router.navigateByUrl(AppRoutes.Root); }
          else { this.router.navigateByUrl(AppRoutes.ErrorPage); }
        }
        else {
          this.eventList = [];
          this.filteredEventList = [];
          this.eventList = data;
          this.filteredEventList = data;
          if (search) {
            this.eventList = this.eventList.filter(function (item) {
              return item.eventName.toLowerCase() == search.toLowerCase();
            });
          }
          this.eventList = this.sort('webhookEventId'); //initially sort based on webhookEventId  
          this.eventsCount = this.eventList.length > 0 ? true : false;
          if (this.eventList.length > 0)
            this.defaultPageSize = this.slPageSize;
          this.showLoader = false;
          this.toggleLayer = false;
        }
      },
      error => {
        this.logger.log("true", error);
        this.showLoader = false;
        this.toggleLayer = false;
      });
  }

  getEventMasterList(type: string) {
    this.showLoader = true;
    this.toggleLayer = true;
    this.webhookService.getEventMaster().subscribe(
      data => {
        if (data.IsError) {
          this.logger.error(data.IsError, data);
          if (this.samesite == "false") { this.router.navigateByUrl(AppRoutes.Root); }
          else { this.router.navigateByUrl(AppRoutes.ErrorPage); }
        }
        else {
          if (type === "viewedit") {
            this.eventStatusList = data;
          }
          else if (type === "add") {
            let configuredEventsList = [];
            this.eventList.forEach(function (value) {
              configuredEventsList.push(value.eventName.toLowerCase());
            });

            let eventmasterStatusList = data;
            //Remove the event status which already configured in this.eventList
            this.eventStatusList = eventmasterStatusList.filter(item => configuredEventsList.indexOf(item.eventName.toLowerCase()) < 0);
          }
          this.showLoader = false;
          this.toggleLayer = false;
        }
      },
      error => {
        this.logger.log("true", error);
      });
  }

  getEventParametersList(eventCode) {
    this.eventParametersList = [];
    this.showLoader = true;
    this.toggleLayer = true;
    this.webhookService.getEventParameters(eventCode).subscribe(
      data => {
        if (data.IsError) {
          this.logger.error(data.IsError, data);
          if (this.samesite == "false") { this.router.navigateByUrl(AppRoutes.Root); }
          else { this.router.navigateByUrl(AppRoutes.ErrorPage); }
        }
        else {
          this.eventParametersList = data;
          this.showDivParameters = true;
          this.showLoader = false;
          this.toggleLayer = false;
        }
      },
      error => {
        this.logger.log("true", error);
      });
  }

  saveEvent() {
    this.submitted = true;
    this.showLoader = true;
    this.toggleLayer = true;
    if (this.slEventParameters.length == 0) { this.showError = true; } else { this.showError = false; }
    if (this.addEditForm.value.eventStatus == "-1") { this.showEventError = true; } else { this.showEventError = false; }
    if (!this.addEditForm.value.url) { this.showUrlError = true; } else { this.showUrlError = false; }
    if (!this.addEditForm.value.secretKeyHeaderName) { this.showSecretKeyHeaderRequired = true; } else { this.showSecretKeyHeaderRequired = false; }

    if (this.showSecretKeyNotValidError || this.showSecretKeyRequired) {
      this.showLoader = false;
      this.toggleLayer = false;
      return false;
    }

    if (!this.showError && !this.showEventError && !this.showUrlError && !this.showSecretKeyHeaderRequired && this.addEditForm.valid) {
      let webhook;
      if (this.isEdited) { webhook = JSON.parse(localStorage.getItem("selectedEventDetails")) as WebhookEvents; }
      else { webhook = ""; }

      this.postdata.webhookEvents = {
        webhookEventId: this.isEdited ? webhook.webhookEventId : 0,
        eventCode: this.addEditForm.value.eventStatus,
        url: this.addEditForm.value.url.trim(),
        secretKeyHeaderName: this.addEditForm.value.secretKeyHeaderName.trim(),
        rPWHSecretKey: localStorage.getItem('RPWHSecretKey'),
        isActive: this.addEditForm.value.status == 1 ? true : false,
        companyId: localStorage.getItem('companyId')
      };

      this.slEventParameters.forEach(element => { this.webhookEventParameters.push({ parameterCode: element }); });

      this.postdata.webhookEventParameters = this.webhookEventParameters;

     // this.showLoader = true;
      this.webhookService.saveWebhookEvent(this.postdata).subscribe(data => {
        if (data.IsError) {
          this.logger.error(data.IsError, data);
          this.toaster.showErrorToaster(data.Error == "Record Already Exist" ? Messages.RecordAlreadyExists : data.Error, "Attention");
          this.showLoader = false;
          this.toggleLayer = false;
        }
        else if (data) {      
          if (this.samesite == "false")
            this.toaster.showSuccessToaster(this.isEdited ? Messages.UpdatedMsg : Messages.AddedMsg, "Success");   
            // this.activeTab = 'webhook';
            // this.submitted = false;
            // this.showLoader = true;
            // this.toggleLayer = true;
            location.reload();
        }
      }, error => {
        this.logger.error("true", error);
        this.showLoader = false;
        this.toggleLayer = false;
        if (this.samesite == "false")
          this.toaster.showErrorToaster(Messages.SomethingWentWrong, "Attention");
      });

     
    }
    else {
      this.submitted = true;
      this.showLoader = false;
      this.toggleLayer = false;
      return false;
    }
  }

  editEventParameters(data: any, e: any) {
    this.activeTab = "addedit";
    this.submitted = false;
    this.eventParametersList = [];
    this.eventParametersList = this.getEventParametersList(data.eventCode);
    this.eventStatusList = this.getEventMasterList("viewedit");
    this.addEditForm.controls['eventStatus'].setValue(data.eventCode);
    this.addEditForm.controls['url'].setValue(data.url);
    this.addEditForm.controls['secretKeyHeaderName'].setValue(data.secretKeyHeaderName);
    this.eventParameterNames = data.parameterName.split(',');
    this.addEditForm.controls['status'].setValue(data.isActive ? 1 : 0);

    //Get Guid
    this.showEyeIcon = true;
    let secretKey = this.getUuid(data.rpwhSecretKey);
    this.addEditForm.controls['rpwhSecretKey'].setValue(secretKey);

    this.slEventParameters = data.parameterName.split(',');
    this.isEdited = true;
    this.showUrlError = false;
    this.showEventError = false;
    this.showEventError = false;

    localStorage.setItem('selectedEventDetails', JSON.stringify(data));
    return true;
  }

  isChecked(paramName: string) {
    if (this.eventParameterNames) {
      for (let i = 0; i < this.eventParameterNames.length; i++) {
        if (this.eventParameterNames[i] == paramName) {
          return true;
        }
      }
    }
    else { return false; }
  }

  getEventParameters(e: any) {
    if (e.target.value != -1) {
      this.showEventError = false;
      this.getEventParametersList(e.target.value);
    }
  }

  searchEvent(input) {
    this.submitted = true;
    if (!input.value.txtSearch) {
      this.showLoader = false;
      this.toggleLayer = false;
      return false;
    }
    // this.getEventLists();   
    this.searchFilter(input, "search");
  }

  filterItem(input) {
    if (!input.value.txtSearch) {
      this.submitted = false;
    } // when nothing has typed   

    // for every inputvalue
    this.searchFilter(input, "filter");
  }

  searchFilter(input, type: string) {
    if (type == "search") { this.eventList = this.filteredEventList.filter(function (item) { return item.eventName.toLowerCase() === input.value.txtSearch.toLowerCase(); }); }
    else if (type == "filter") { this.eventList = this.filteredEventList.filter(function (item) { return item.eventName.toLowerCase().indexOf(input.value.txtSearch.toLowerCase()) === 0; }); }

    this.eventList = this.sort('webhookEventId'); //initially sort based on webhookEventId  
    this.eventsCount = this.eventList.length > 0 ? true : false;
    if (this.eventList.length > 0)
      this.defaultPageSize = this.slPageSize
    this.p = 1;
  }

  isEventParameterChecked(e: any) {
    this.showError = false;
    if (e.target.checked == true) {
      this.slEventParameters.push(e.target.value);
    }
    else {
      this.slEventParameters = this.slEventParameters.filter(function (item) {
        return item.toLowerCase() !== e.target.value.toLowerCase();
      });
      if (this.slEventParameters.length == 0) {
        this.showError = true;
      }
    }
  }

  changePageSize(e: any) {
    this.slPageSize = e.target.value;
    this.p = 1;
    this.getEventLists();
  }

  generateUuid() {
    let newUuid = this.helper.generateUuid();
    localStorage.setItem('RPWHSecretKey', RSignDefault.SecretKeyText + newUuid);
    localStorage.setItem('HiddenRPWHSecretKey', RSignDefault.SecretKeyText + newUuid.substr(0, 4) + '********************' + newUuid.substr(-4, 4));
    return newUuid;
  }

  getUuid(secretKey) {
    if (secretKey) {
      localStorage.setItem('RPWHSecretKey', secretKey);
      localStorage.setItem('HiddenRPWHSecretKey', secretKey.substr(0, 9) + '********************' + secretKey.substr(-4, 4)); //Secretkey is including RPWH-. So its taken substr(0, 9)
      return secretKey;
    }
    else {
      secretKey = this.generateUuid();
      return RSignDefault.SecretKeyText + secretKey;
    }
  }

  copyToClipBoard() {
    let secretKeyText = localStorage.getItem('RPWHSecretKey');
    this.clipboardApi.copyFromContent(secretKeyText);
    this.toaster.showSuccessToaster("Secret Key copied successfully.", "Success");
  }

  refreshGuid() {
    var generatedNewuuid = this.generateUuid();
    let secretKeyText = RSignDefault.SecretKeyText + generatedNewuuid;
    this.addEditForm.controls['rpwhSecretKey'].setValue(secretKeyText);
    localStorage.setItem('HiddenRPWHSecretKey', RSignDefault.SecretKeyText + generatedNewuuid.substr(0, 4) + '********************' + generatedNewuuid.substr(-4, 4));

    if (this.showEyeIcon) { secretKeyText = localStorage.getItem('RPWHSecretKey'); }
    else { secretKeyText = localStorage.getItem('HiddenRPWHSecretKey'); }

    this.addEditForm.controls['rpwhSecretKey'].setValue(secretKeyText);
    this.toaster.showSuccessToaster("Secret Key generated successfully.", "Success");

    this.showSecretKeyRequired = false;
    this.showSecretKeyNotValidError = false;
  }

  toggleSecretKey() {
    let secretKeyText = "";
    if (!this.showEyeIcon) { secretKeyText = localStorage.getItem('RPWHSecretKey'); }
    else { secretKeyText = localStorage.getItem('HiddenRPWHSecretKey'); }
    this.addEditForm.controls['rpwhSecretKey'].setValue(secretKeyText);
    this.showEyeIcon = !this.showEyeIcon;
  }

  sort(name: string) {
    this.isDesc = (name && this.sortingName !== name) ? true : !this.isDesc; // default to descending, otherwise toggle
    this.sortingName = name;
    return this.helper.sortTable(this.isDesc, this.eventList, name);
  }

  setAppLanguage() {
    this.helper.setAppLanguage();
  }

  IsValidUUID(event: any) {
    var editedValue = event.target.value;
    if (!this.showEyeIcon) {
      var oldValue = localStorage.getItem('HiddenRPWHSecretKey');
      if (event.target.value == "") { this.showSecretKeyRequired = true; this.showSecretKeyNotValidError = false; }
      else if (oldValue != editedValue) {
        this.showSecretKeyRequired = false;
        this.showSecretKeyNotValidError = true;
      }
      else {
        this.showSecretKeyNotValidError = false;
        this.showSecretKeyRequired = false;
      }
    }
    else {
      // var first5Chars = event.target.value.substr(0, 5); 
      //var nextChars = event.target.value.substr(5); //next chars in the secretkey
      var nextCharsLength = event.target.value.length;  //the secretkey length should be minimum 20 chars including prefix
      localStorage.setItem('RPWHSecretKey', event.target.value);
      localStorage.setItem('HiddenRPWHSecretKey', event.target.value.substr(0, 4) + '********************' + event.target.value.substr(-4, 4));

      if (event.target.value == "") { this.showSecretKeyRequired = true; this.showSecretKeyNotValidError = false; }
      else if (nextCharsLength >= 20) {
        this.showSecretKeyNotValidError = false;
        this.showSecretKeyRequired = false;
      }
      else {
        this.showSecretKeyRequired = false;
        this.showSecretKeyNotValidError = true;
      }
    }
  }

  allowOnlyAlphaNumericWithHyphens(event) {
    return this.helper.allowOnlyAlphaNumericWithHyphens(event);
  }

  onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');    
    if (this.helper.onPasteOrDropTextValidation(pastedText)) {
      event.preventDefault();
      event.stopPropagation();      
    }
  }

  onDrop(ev, type) {
    ev.preventDefault();
    var droppedText = ev.dataTransfer.getData("text");    
    if (this.helper.onPasteOrDropTextValidation(droppedText)) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    else {

      if(type == 1)
       this.addEditForm.controls['secretKeyHeaderName'].setValue(droppedText.substr(0, 50));
      else if(type == 2)
        this.addEditForm.controls['rpwhSecretKey'].setValue(droppedText.substr(0, 50));
    }
  }

  onDragOver(event, type) {    
    event.preventDefault();
  }

}