import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { Router } from '@angular/router';
import { Helper } from '../common/class/helper';
import { ApiRoutes } from '../shared/constants/constants'
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })

export class LogService {
    baseUrl: string | undefined;
    routeUrl: string | undefined;
    token: any;

    constructor(private http: HttpClient, private router: Router, private helper: Helper) {
        this.baseUrl = environment.RootApi;
        this.token = localStorage.getItem('userToken');
    }

    debug(msg: string, data: any) {
        this.writeToLog(msg, "Debug", data);
    }

    info(msg: string, data: any) {
        this.writeToLog(msg, "Info", data);
    }

    warn(msg: string, data: any) {
        this.writeToLog(msg, "Warn", data);
    }

    error(msg: string, data: any) {
        this.writeToLog(msg, "Error", data);
    }

    fatal(msg: string, data: any) {
        this.writeToLog(msg, "Fatal", data);
    }

    log(msg: string, error: any) {
        this.writeErrorToLog(msg, "Error", error);
    }

    private writeToLog(msg: string, level: string, data: any) {
        let retMsg: string = "";
        retMsg = new Date() + " - ";
        retMsg += "Type: " + level + " - ";
       // retMsg += data.Error.status == undefined ? data.Error : data.Error.status + " - " + data.Error.title == undefined ? data.Error : data.Error.title;
        retMsg += " - Message: " + data.Message;
        console.log(retMsg);
        let angularLogModel = { logInfo: retMsg };
        this.saveLog(angularLogModel);
    }

    private writeErrorToLog(msg: string, level: string, error: any) {
        let retMsg: string = "";
        retMsg = new Date() + " - ";
        retMsg += "Type: " + level + " - ";
        retMsg += "Error: " + error + " - ";
        retMsg += " - Message: " + msg;
        console.log(retMsg);
        let angularLogModel = { logInfo: retMsg };
        this.saveLog(angularLogModel);
    }

    saveLog(angularLogModel: any) {
       this.errorLogUpdated(angularLogModel);
        const formData: FormData = angularLogModel;
        this.routeUrl = this.baseUrl + ApiRoutes.AngularLogInfo;
        let headers = new HttpHeaders({ 'AuthToken': this.token, 'Content-Type': 'application/json;charset=UTF-8' });
        return this.http.post<any>(`${this.routeUrl}`, formData, { headers: headers });
    }

    errorLogUpdated(angularLogModel: any) {
        console.log("Error is updated in api log file on " + new Date() + " and error is: " + angularLogModel.logInfo);
    }
}

