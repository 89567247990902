import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { ErrorpageComponent } from './errorpage/errorpage.component'
import { AppRoutes } from './shared/constants/constants'
import { LoginComponent } from './login/login.component'
import { LangTranslationComponent } from './components/lang-translation/lang-translation.component';
import { WebhookComponent } from './components/webhook/webhook.component';
import { TokenGuardService } from './shared/guards/token-guard';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomepageComponent },
  { path: 'home?:rpk', component: HomepageComponent },
  { path: 'translation', component: LangTranslationComponent, canActivate:[TokenGuardService]  },
  { path: 'webhook', component: WebhookComponent, canActivate:[TokenGuardService] }, 
  { path: 'error/:errorDesc', component: ErrorpageComponent },
  { path: '404', component: ErrorpageComponent },
  { path: '**', redirectTo: AppRoutes.PageNotFound }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
