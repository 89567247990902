<div *ngIf="samesite === 'false'">
    <app-header></app-header>
</div>

<div class="disable-background" *ngIf="toggleLayer"></div>

<div class="container-fluid" id="webhookContainer" [ngClass]="samesite === 'false' ? 'webhookContainerAngular': 'webhookContainerMVC'">
    <div class="row">
        <div class="col-md-12">
            <div *ngIf="samesite === 'false'" class="viewsettings"> {{ "ViewSettings" | translate }}: {{company}} </div>
            <!-- settings page tabs -->
            <div class="main-section setting-section">
                <nav class="setting-tabs">
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        <a class="nav-item nav-link active" id="nav-webhook-tab" [ngClass]="{ 'active':activeTab==='webhook'}" title="Webhook" data-toggle="tab" href="#nav-webhook" role="tab" aria-controls="nav-webhook" aria-selected="true" (click)="clickWebhookTab('webhook')">{{ "Webhooks" | translate }}</a>
                        <a class="nav-item nav-link" id="nav-addedit-tab" [ngClass]="{ 'active':activeTab==='addedit'}" title="Add / Edit Webhook" data-toggle="tab" href="#nav-addedit" role="tab" aria-controls="nav-addedit" aria-selected="true" (click)="clickAddEditTab('addedit')">
                            {{ "ADD/EDITWEBHOOK" | translate }}</a>
                    </div>
                </nav>

                <div *ngIf="showLoader">
                    <app-loadingicon></app-loadingicon>
                </div>

                <!-- tabs content -->
                <div class="tab-content" id="nav-tabContent">

                    <div class="tab-pane" id="nav-webhook" [ngClass]="{ 'active':activeTab==='webhook'}" role="tabpanel" aria-labelledby="nav-webhook-tab">
                        <div class="right-section left-push">
                            <div class="tab-content" id="nav-tabContent">
                                <div class="bg-white">
                                    <div class="tab-content bg-white m-0 p-0">
                                        <div id="Address_Book" class="container tab-pane active p-0 col-12">
                                            <ul class="form-content tab-search">
                                                <li class="col-sm-12 col-md-12 d-md-flex">
                                                    <form class="col-sm-12 col-md-8" [formGroup]="searchForm" (ngSubmit)="searchEvent(searchForm)">
                                                        <div class="col-sm-12 col-md-10 d-md-flex">
                                                            <div class="col-sm-12 col-md-12 d-flex p-0 mt-2 mb-2 pl-md-2 pr-md-2 mt-md-0 mb-md-0">
                                                                <div class="col-sm-12 col-md-3 searcheventtext">{{ "SearchEvent" | translate }}
                                                                </div>
                                                                <input class="w-100 form-control col-md-8" type="search" formControlName="txtSearch" name="txtSearch" title="provide search value" placeholder="Search" autocomplete="off" (input)="filterItem(searchForm)">
                                                                <span class="box search"> <i class="icofont-search"  title="search" (click)="searchEvent(searchForm)"></i> </span>
                                                            </div>
                                                            <div *ngIf="submitted && s.txtSearch.errors" class="errorMessage">
                                                                <div *ngIf="s.txtSearch.errors.required">*{{ "ValidationMessages.Required" | translate }}</div>
                                                            </div>
                                                            <div style="margin-left:5px;" style="display: none;">
                                                                <button title="search" class="btn btn-primary">{{ "Search" | translate }}</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <div>
                                                        <button class="btn btn-green addwebhook" title="Add Webhook" (click)="clickAddEditTab('addedit')"> {{ "AddWebhook" | translate }}</button>
                                                    </div>
                                                </li>
                                                <li style="margin-right:10px;">
                                                    <!-- <div class="table_responsive"> -->
                                                    <div *ngIf="eventsCount">
                                                        <table class="table table-bordered table-sm m-0 cont_table table-responsive-sm">
                                                            <thead>
                                                                <tr style="background-color: #c9d0e3;">
                                                                    <th class="sortable" [ngClass]="{'asc':(sortingName==='eventName'&&isDesc===false), 'desc':(sortingName==='eventName'&&isDesc===true)}" (click)="sort('eventName')">{{ "Event" | translate }} </th>
                                                                    <th class="sortable" [ngClass]="{'asc':(sortingName==='url'&&isDesc===false), 'desc':(sortingName==='url'&&isDesc===true)}" (click)="sort('url')">{{ "URL" | translate }} </th>
                                                                    <th class="sortable" [ngClass]="{'asc':(sortingName==='secretKeyHeaderName'&&isDesc===false), 'desc':(sortingName==='secretKeyHeaderName'&&isDesc===true)}" (click)="sort('secretKeyHeaderName')">Secret Key Header Name</th>
                                                                    <th class="sortable" [ngClass]="{'asc':(sortingName==='rpwhSecretKey'&&isDesc===false), 'desc':(sortingName==='rpwhSecretKey'&&isDesc===true)}" (click)="sort('rpwhSecretKey')"> {{ "SecretKey" | translate }} </th>
                                                                    <th class="sortable" [ngClass]="{'asc':(sortingName==='modfiedBy'&&isDesc===false), 'desc':(sortingName==='modfiedBy'&&isDesc===true)}" (click)="sort('modfiedBy')"> {{ "ModifiedBy" | translate }}</th>
                                                                    <th class="sortable" [ngClass]="{'asc':(sortingName==='modifiedDate'&&isDesc===false), 'desc':(sortingName==='modifiedDate'&&isDesc===true)}" (click)="sort('modifiedDate')"> {{ "ModifiedDate" | translate }} </th>
                                                                    <th> {{ "Actions" | translate }} </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let rowData of (eventList)  | paginate: { itemsPerPage:defaultPageSize, currentPage: p }">
                                                                    <!-- <tr *ngFor="let rowData of (eventList)"> -->
                                                                    <td [ngClass]="{'sort-column':sortingName==='eventName'}">
                                                                        {{rowData.eventName}}</td>
                                                                    <td [ngClass]="{'sort-column':sortingName==='url'}">{{rowData.url}}</td>
                                                                    <td [ngClass]="{'sort-column':sortingName==='secretKeyHeaderName'}">{{rowData.secretKeyHeaderName}}</td>
                                                                    <td [ngClass]="{'sort-column':sortingName==='rpwhSecretKey'}">{{rowData.rpwhSecretKey}}</td>
                                                                    <td [ngClass]="{'sort-column':sortingName==='modfiedBy'}">
                                                                        {{rowData.modfiedBy}}</td>
                                                                    <td class="linkview" [ngClass]="{'sort-column':sortingName==='modifiedDate'}">
                                                                        {{rowData.modifiedDate | date: 'yyyy-MM-dd'}}
                                                                    </td>

                                                                    <td class="linkview"><a (click)="editEventParameters(rowData,$event)" title="view or edit webhook"> {{ "ViewEdit" | translate }}</a>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                        <pagination-controls class="my-pagination" previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="p = $event"></pagination-controls>

                                                        <div class="col-md-2 d-flex p-0 mt-2 mb-2 pl-md-2 pr-md-2 mt-md-0 mb-md-0 divPageSize">
                                                            <div style="margin-top: 40px;"> <label class="lblPageSize"> {{ "PageSize" | translate }}: &nbsp;</label></div>
                                                            <select [ngModel]="defaultPageSize" class="form-control slPageSize" (change)="changePageSize($event,defaultPageSize)">                                                               
                                                                    <option value="5">5</option>
                                                                    <option value="10">10</option>
                                                                    <option value="15">15</option>
                                                                    <option value="15">20</option>
                                                                </select>
                                                        </div>
                                                    </div>

                                                    <table *ngIf="!eventsCount" class="table table-bordered table-sm m-0 cont_table table-responsive-sm">
                                                        <tbody>
                                                            <tr>
                                                                <td class="no-pointer">{{ "NoWebhooksAreExists" | translate }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <!-- </div> -->
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane" id="nav-addedit" [ngClass]="{ 'active':activeTab==='addedit'}" role="tabpanel" aria-labelledby="nav-addedit-tab">
                        <div class="right-section left-push">
                            <div class="tab-content" id="nav-tabContent">
                                <div class="bg-white">
                                    <div class="tab-content bg-white m-0 p-0">
                                        <div id="event_List" class="container tab-pane active p-0 col-12">
                                            <form [formGroup]="addEditForm" (ngSubmit)="saveEvent()">
                                                <ul class="form-content tab-search">
                                                    <div class="col-sm-12 col-md-12 d-flex p-0 mt-2 mb-2 pl-md-2 pr-md-2 mt-md-0 mb-md-0 divEvent">
                                                        <div class="col-sm-12 col-md-2"> <label class="lblEvent" for="event">{{ "Event" | translate }}:</label>
                                                        </div>
                                                        <select formControlName="eventStatus" class="col-md-4 form-control slEventStatus" required (change)="getEventParameters($event)" [attr.disabled]="isEdited ? true : null">
                                                            <option value="-1" disabled>Select Event</option>
                                                            <option value="{{eve.eventCode}}"
                                                                *ngFor="let eve of eventStatusList">
                                                                {{eve.eventName}}</option>
                                                        </select>
                                                        <div *ngIf="submitted && showEventError" class="errorMessage">
                                                            *{{ "ValidationMessages.EventRequired" | translate }}
                                                        </div>
                                                    </div>

                                                    <div style="height: 20px;"></div>
                                                    <div class="col-sm-12 col-md-12 d-flex p-0 mt-2 mb-2 pl-md-2 pr-md-2 mt-md-0 mb-md-0 divRPWHSecretKeyHeader">
                                                        <div class="col-sm-12 col-md-2"> <label class="lblRPWHSecretKeyTextHeader" for="lblRPWHSecretKeyHeader">Secret Key Header Name:</label></div>
                                                        <input class="lblRPWHSecretKeyHeader col-md-4 form-control" formControlName="secretKeyHeaderName" minlength="2" maxlength="50" autocomplete="off" (paste)="onPaste($event)" required [ngClass]="{ 'is-invalid': submitted && f.secretKeyHeaderName.errors }"
                                                            (keypress)="allowOnlyAlphaNumericWithHyphens($event)" (drop)="onDrop($event, 1)" (dragover)="onDragOver($event, 1)">
                                                        <div *ngIf="f.secretKeyHeaderName.errors" class="errorMessage">
                                                            <div *ngIf="submitted && showSecretKeyHeaderRequired" class="errorMessage">
                                                                *Secret Key Header Name is required
                                                            </div>
                                                            <div *ngIf="submitted && f.secretKeyHeaderName.errors.minlength" class="errorMessage">
                                                                *The length of the secret key header name should be minimum 2 characters
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style="height: 20px;"></div>
                                                    <div class="col-sm-12 col-md-12 d-flex p-0 mt-2 mb-2 pl-md-2 pr-md-2 mt-md-0 mb-md-0 divRPWHSecretKey">
                                                        <div class="col-sm-12 col-md-2"> <label class="lblRPWHSecretKeyText" for="lblRPWHSecretKey">{{ "SecretKey" | translate }}:</label>
                                                        </div>
                                                        <input class="lblRPWHSecretKey col-md-4  form-control" formControlName="rpwhSecretKey" autocomplete="off" required maxlength="50" (keypress)="allowOnlyAlphaNumericWithHyphens($event)" (focusout)="IsValidUUID($event)" (drop)="onDrop($event, 2)" (dragover)="onDragOver($event, 2)">
                                                        <a [ngClass]="showEyeIcon === true ? 'showEyeIcon': 'hideEyeIcon'" (click)="toggleSecretKey()" title="Click here to view or hide"></a>
                                                        <a class="refreshTemplateIcon" (click)="refreshGuid()" title="Click to Refresh"></a>
                                                        <a class="copyTemplateIcon" (click)="copyToClipBoard()" title="Click to Copy"></a>

                                                        <div *ngIf="showSecretKeyRequired" class="errorMessage">
                                                            *{{ "ValidationMessages.showSecretKeyRequired" | translate }}
                                                        </div>

                                                        <div *ngIf="showSecretKeyNotValidError" class="errorMessage">
                                                            *{{ "ValidationMessages.showSecretKeyNotValidError" | translate }}
                                                        </div>

                                                    </div>

                                                    <div style="height: 20px;"></div>
                                                    <div class="col-sm-12 col-md-12 d-flex p-0 mt-2 mb-2 pl-md-2 pr-md-2 mt-md-0 mb-md-0 divURL">
                                                        <div class="col-sm-12 col-md-2"> <label class="lblURLText" for="lblurl">{{ "URL" | translate }}:</label>
                                                        </div>
                                                        <input class="lblURL col-md-4 form-control" type="text" formControlName="url" autocomplete="off" required [ngClass]="{ 'is-invalid': submitted && f.url.errors && f.url.invalid }">
                                                        <div *ngIf="f.url.errors && f.url.invalid" class="errorMessage">
                                                            <div *ngIf="submitted && showUrlError">*{{ "ValidationMessages.URLRequired" | translate }}
                                                            </div>
                                                            <div *ngIf="f.url.touched && f.url.errors.pattern">*{{ "ValidationMessages.ValidUrl" | translate }}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style="height: 20px;"></div>
                                                    <div *ngIf="showDivParameters" class="col-sm-12 col-md-12 d-flex p-0 mt-2 mb-2 pl-md-2 pr-md-2 mt-md-0 mb-md-0 divParameters">
                                                        <div class="col-sm-12 col-md-2"> <label class="lblParameters" for="parameters">{{ "Parameters" | translate }}:</label> </div>
                                                        <ul class="ulParameters col-md-4">
                                                            <li class="listParameters" *ngFor="let evparam of eventParametersList">
                                                                <label for="{{evparam.parameterCode}}">
                                                                    <input class="form-checkbox"
                                                                        type="checkbox"  
                                                                        (change)="isEventParameterChecked($event)"                                                                      
                                                                        [checked]="isChecked(evparam.parameterCode)"
                                                                        value="{{evparam.parameterCode}}"
                                                                         />
                                                                    &nbsp; {{evparam.parameterName}}
                                                                </label>
                                                            </li>
                                                        </ul>
                                                        <div *ngIf="submitted && showError" class="errorMessage">*{{ "ValidationMessages.AtLeastOneParameter" | translate }}</div>
                                                    </div>

                                                    <div style="height: 20px;"></div>
                                                    <div class="col-sm-12 col-md-12 d-flex p-0 mt-2 mb-2 pl-md-2 pr-md-2 mt-md-0 mb-md-0 divEvent">
                                                        <div class="col-sm-12 col-md-2 divStatus"> <label class="lblStatusText" for="event">{{ "Status" | translate }}:</label> </div>
                                                        <select formControlName="status" class="col-md-4 form-control slStatus">
                                                            <option value="1">Active</option>
                                                            <option value="0">Disable</option>
                                                        </select>
                                                    </div>

                                                    <div class="col-sm-12 col-md-12 d-md-flex">
                                                        <button title="save webhook" class="btn  btncenteralign capitalizeText">{{ "Save" | translate }}</button>
                                                        <a class="btn btn-danger btndangercenteralign ml-5 capitalizeText" title="cancel" (click)="clickWebhookTab('webhook')">{{ "Cancel" | translate }}</a>
                                                    </div>
                                                </ul>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>

<div *ngIf="samesite === 'false'">
    <app-footer></app-footer>
</div>