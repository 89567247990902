<div class="header d-flex justify-content-between align-items-center logo-wrapper">
    <a href="{{login}}" target="_blank">
        <img class="rsign-logo img-responsive" [src]="rsignCompanyLogo">
    </a>

 

    <div class="multilingual clearfix">
        <div id="dvsignup">
            <button type="submit" id="btnSignup" class="btn btn-lg btn-defaultSignup d-sm-none d-md-block capitalizeText" value="Sign up">
                <span id="spnSignup"><a href="#">Sign up</a> </span>
            </button>
        </div>
        <div id="selLanguage" class="clearfix">
            <div class="languageHead" id="selectedlang" value="en-us">English
                <!-- <img src="../../assets/Images/down-arrow.png"> -->
            </div>
        </div>
    </div>
</div>


<div class="container-fluid main-container">
    <div class="container">
        <div class="wrapperLogOn fadeInDown LogOn hidden" id="signInFrm" style="width:80%">
            <a href="#" id="creatNewlink" class="hidden" style="display: none;">Create New Account</a>
        
            <div class="element-groupLogOn" style="display: -webkit-inline-box;"> <h2>Welcome back!</h2> </div>

            <form class="SingnIn logOnWidth-100 custom validatable" id = "frmSignIn" method="POST">
                <div class="element-groupLogOn">
                    <div class="form-groupLogOn input-wrapper input-group">
                        <i class="fa fa-envelope envelopeIcon" aria-hidden="true"></i>                    
                        <input class="form-control validate[required,custom[email]]" type="text" id="email" name="Email" autocomplete="off" placeholder="Email address"
                                                 style="border-radius: 5px;height: 44px;" data-validation-engine="validate[required,custom[email]]">
                    </div>
                    <div class="form-groupLogOn input-wrapper input-group">
                        <i class="fa fa-key keyiconPwd" aria-hidden="true"></i>
                        <input class="form-control  access-password-field validate[required]" type="password" id="password" name="UserPassword" autocomplete="off" placeholder="Password"                        
                                                  style="border-radius: 5px;height: 44px;" data-validation-engine="access-password-field validate[required]">
                        
                        <i class="fa fa-eye-slash toggle-password eyeIcon" aria-hidden="true"></i>
                        <div class="input-group-append bg d-sm-none">
                            <a class="input-group-text" href="#" id="forgetPassword">Forgot Password</a>
                        </div>
                    </div>
                    <span class="hidemyerrorsummary"></span>
                </div>
                <div class="element-groupLogOn" style="display: -webkit-inline-box;">
                    <label class="checkboxes" for="checkbox1" style="width:240px;">
                        <input type="checkbox" class="form-checkbox" id="RememberMe" name="RememberMe" > Remember me
                    </label>
                    <label class="checkboxes" for="checkbox2">
                        <input type="checkbox" class="form-checkbox" id="remeberPassword" name="RememberPassword"> Remember password           
                    </label>
                </div>
                <div class="logon-btnNew">
                    <button type="button" id="btnSignin" class="btn btn-lg btn-default capitalizeText" value="Login">
                        <span  id="spnSignIn">Sign In</span>
                    </button>
                </div>               
                <div class="or_options text-center">                  
                        <div class="or_text "><span style="font-size: 15px;">or <a href="#" class="ml-2" style="font-weight: 600; color: #337ab7;">SignUp</a> </span></div> 
                </div>
            </form>          
        </div>
    </div> 
</div>

<!-- <div class="container-fluid login-container">
    <div class="login-register-form">
        <div class="login-form show-xs" style="margin-left: 206px;">
            <h2>Already signed up?</h2>
            <form class="validatable" method="POST">
                <div class="element-group">
                    <input class="form-control" type="text" id="email" name="Email" autocomplete="off" placeholder="Email" data-validation-engine="validate[required,custom[email]]">
                    <input class="form-control" type="password" name="UserPassword" placeholder="Password" data-validation-engine="validate[required]">
                </div>
                <div class="element-group">
                    <input type="checkbox" class="form-checkbox" id="rememberMe" checked> Remember Me
                </div>
                <div class="element-group">
                    <input type="checkbox" class="form-checkbox" id="remeberPassword" checked> Remember Password
                </div>
                <div class="d-flex justify-content-between align-items-center mt-2">                  
                    <button class="btn btn-green btncenteralign" type="submit">Sign In</button>
                </div>
            </form>
        </div>
    </div>
</div> -->

<app-footer></app-footer>