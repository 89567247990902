import { SafeUrl } from "@angular/platform-browser";

export class FileToUpload {
    fileName: string = "";
    fileSize: string = "";
    fileType: string = "";
    fileAsBase64: string = "";
    resourceKeyId:string="";
}


export class ImageModel
    {
        name:string;
        data:string;
        contentType:string;
        Url:SafeUrl;
    }