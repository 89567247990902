import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {

  constructor(private router: Router) { }

  getAppLanguage() {
    const val = localStorage.getItem('appLang');
    if (val !== undefined && val !== null) { return val; } 
    else { return 'en-us'; }
  }

  setAppLanguage(lang: string) { localStorage.setItem('appLang', lang); }

  getuserData() { return JSON.parse(sessionStorage.getItem('userData')); }

  setuserData(data) { sessionStorage.setItem('userData', JSON.stringify(data)); }

}
