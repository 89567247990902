import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest, HttpEvent } from '@angular/common/http';
import { Helper } from '../common/class/helper';
import { Router } from '@angular/router';
import { ApiRoutes, LanguageApiRoutes } from '../shared/constants/constants'
import { environment } from '../../environments/environment'
import { catchError } from 'rxjs/operators';
import { LanguageTranslatedData, LanguageTranslatedReq, LanguageTranslationInfo, LanguageTranslationList } from '../class/languageTranslation';
import { Observable } from 'rxjs';
import { FileToUpload, ImageModel } from '../class/FileToUpload';
@Injectable({
  providedIn: 'root'
})
export class LangTranslationService {
  baseUrl: string | undefined;
  routeUrl: string | undefined;
  token: any;
  secretKey: any;
  email: any;
  userId: any;
  constructor(private http: HttpClient, private router: Router, private helper: Helper) {
    this.baseUrl = environment.RootApi;
    this.token = localStorage.getItem('userToken');
    this.email = localStorage.getItem('email');
    this.userId = localStorage.getItem('userId');
  }    

  get requestOptions() {
    return {
      headers: new HttpHeaders({
        'AuthToken': this.token,
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json'
      })
    };
  }
  getLanguagelist(pageIndex, pageSize) {
    this.routeUrl = this.baseUrl + LanguageApiRoutes.GetLanguageList + "?useremail=" + this.email + "&page=" + pageIndex + "&pageSize=" + pageSize;
    return this.http.get<LanguageTranslationInfo>(`${this.routeUrl}`, { ...this.requestOptions }).pipe(catchError(this.helper.handleError));
  }

  getTranslationListByLanguage(languageCode: string, filterType: string, pageIndex, pageSize, searchText: string, translationType: string): Observable<any> {
    this.routeUrl = this.baseUrl + LanguageApiRoutes.GetFilteredTranslation + "?languageCode=" + languageCode + "&filterType=" + filterType 
    + "&page=" + pageIndex + "&pageSize=" + pageSize + "&searchText=" + searchText + "&translationType=" + translationType;
    return this.http.get<LanguageTranslationList[]>(`${this.routeUrl}`, { ...this.requestOptions }).pipe(catchError(this.helper.handleError));
  }

  saveTranslationList(postdata: LanguageTranslatedData[], status: string): Observable<any> {
    let formData: LanguageTranslatedReq = {
      email: this.email, status: status, LanguageTranslatedData: postdata, userId: this.userId
    };
    this.routeUrl = this.baseUrl + LanguageApiRoutes.SaveLanguageTranslation;
    return this.http.post<any>(`${this.routeUrl}`, formData, { ...this.requestOptions }).pipe(catchError(this.helper.handleError));
  }

  public uploadFile(postdata: FileToUpload): Observable<any> {
    let formData: FileToUpload = postdata;
    this.routeUrl = this.baseUrl + LanguageApiRoutes.UploadLanguageDocument;
    return this.http.post<FileToUpload>(`${this.routeUrl}`, formData, { ...this.requestOptions }).pipe(catchError(this.helper.handleError));
  }

  public downloadFile(resourceKeyId: any): Observable<HttpEvent<Blob>> {
    let headers = new HttpHeaders();
    this.routeUrl = this.baseUrl + LanguageApiRoutes.ViewLanguageTranslationDocument + "?ResourceKeyId=" + resourceKeyId;
    return this.http.get(`${this.routeUrl}`, {
      headers: headers.append('AuthToken', this.token),
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    });
  }
  public DisplayFile(resourceKeyId: any): Observable<ImageModel[]> {
    let headers = new HttpHeaders();
    this.routeUrl = this.baseUrl + LanguageApiRoutes.DisplayLanguageTranslationDocument + "?ResourceKeyId=" + resourceKeyId;
    return this.http.get<ImageModel[]>(`${this.routeUrl}`, { ...this.requestOptions }).pipe(catchError(this.helper.handleError));
  } 
}
