<div class="wrapper" *ngIf="this.errorDescription === 'un-authorized-user'">
    <h4 class="SupportCaption">{{unAuthorizedUser}}</h4>
    <ul class="supportInfo" *ngIf="samesite === 'false'">
        <li>Ready to start using RSign?
            <a class="rsignuserlogout" target="_blank" href="{{login}}">Log in</a></li>
        <li>New to RSign? <a target="_blank" href="{{training}}">View how-to-guides, videos, and other training content</a></li>
    </ul>
</div>


<div class="wrapper" *ngIf="this.errorDescription === 'page-not-found'">
    <h4 class="SupportCaption">{{pageNotFound}}</h4>
    <ul class="supportInfo" *ngIf="samesite === 'false'">
        <li>Ready to start using RSign?
            <a class="rsignuserlogout" target="_blank" href="{{login}}">Log in</a></li>
        <li>New to RSign? <a target="_blank" href="{{training}}">View how-to-guides, videos, and other training content</a></li>
    </ul>
</div>

<div class="wrapper" *ngIf="this.errorDescription === 'something-went-wrong'">
    <h4 class="SupportCaption">{{somethingWentWrong}}</h4>
    <ul class="supportInfo" *ngIf="samesite === 'false'">
        <li>Ready to start using RSign?
            <a class="rsignuserlogout" target="_blank" href="{{login}}">Log in</a></li>
        <li>New to RSign? <a target="_blank" href="{{training}}">View how-to-guides, videos, and other training content</a></li>
    </ul>
</div>