import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError, } from 'rxjs';
import { AppError } from '../class/app-error';
import { UserSessionService } from '../user-session.service'
import { TranslateService } from '@ngx-translate/core';
import { TranslationLoaderService } from '../translation-loader.service';
import { v4 as uuid } from 'uuid';

@Injectable({ providedIn: 'root' })

export class Helper {
  appLang = '';

  constructor(private userSession: UserSessionService, private langLoaderSvc: TranslationLoaderService, private langSvc: TranslateService) {

    }
    sortTable(isDesc: boolean, tableData: any, colName: string) {
        // Return the sorted tableData
        return tableData.sort((a, b) => {
          if (isDesc) {
            if (Date.parse(a[colName])) {
              return new Date(a[colName]) < new Date(b[colName])  ? 1 : new Date(a[colName])  > new Date(b[colName])  ? -1 : 0;
            } else {
              return a[colName] < b[colName]  ? 1 : a[colName]  > b[colName]  ? -1 : 0;
            }
          } else {
            if (Date.parse(a[colName])) {
              return new Date(a[colName]) > new Date(b[colName])  ? 1 : new Date(a[colName])  < new Date(b[colName])  ? -1 : 0;
            } else {
              return a[colName] > b[colName]  ? 1 : a[colName]  < b[colName]  ? -1 : 0;
            }
          }
        });
    }

    handleError(err: HttpErrorResponse): Observable<any> {
      const appError = new AppError(err);
      const errorStatus = err.status;    
      const error: any = err;
      let errorMsg = '';    
  
      if (error.message !== undefined ) { errorMsg = error.message; } 
      const retMsg = `Error (${errorStatus}): ${errorMsg}`;
      appError.Message = retMsg;   
      appError.Type = error.error.type;
      appError.IsError = true;
      appError.Error = error.error;
  
      return of(appError);
    }

    allowOnlyAlphaNumericWithHyphens(event) {
      var inputVal = String.fromCharCode(event.keyCode);
      // Allow numbers, alpahbets, Hyphens[-] only
      if (/[a-zA-Z0-9-]/.test(inputVal)) {  
        return true;
      } else {      
        event.preventDefault();
        return false;
      }
    }

    setAppLanguage() {
      this.appLang = this.userSession.getAppLanguage();
      this.langSvc.setDefaultLang(this.appLang);
      this.langLoaderSvc.initLang(); //initiating language transaltion
    }

    generateUuid() {
      return uuid();
    }

    onPasteOrDropTextValidation(pastedText){
      var format = /[!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/? ]+/;
      if(format.test(pastedText)){
        return true; 
      } else {
        return false;
      }
    }
}
