<div *ngIf="samesite === 'false'">
    <app-header></app-header>
</div>

<div class="disable-background" *ngIf="toggleLayer"></div>

<div class="container-fluid" [ngClass]="samesite === 'false' ? 'webhookContainerAngular': 'webhookContainerMVC'">
    <div *ngIf="showLoader">
        <app-loadingicon></app-loadingicon>
    </div>

    <div class="row" style="margin-bottom: -10px;">
        <div class="col-md-2  divTopLength">
            <label class="lblMarginBottom">Language:</label>
            <select name="language" id="language" class="col-md-12 divScroll" (change)="changeLanguage($event,true)" [(ngModel)]="languageCode">
                <option *ngFor="let lang of LanguageList" value={{lang.optionValue}}>
                    {{lang.optionName}}
                </option>
            </select>
        </div>

        <div class="col-md-2 pl-0 divTopLength" style="padding-left: 15px;padding-right: 0px;">
            <label class="lblMarginBottom">Translation Type:</label>
            <select name="translationType" id="translationType" class="col-md-12 divScroll" (change)="changeTranslationType($event)" [(ngModel)]="translationType">
                <option value="0"> Select </option>
                <option value="1"> Missing Translations </option>              
            </select>
        </div>

        <div class="col-md-2 divTopLength">
            <label class="lblMarginBottom">Page:</label>
            <select name="filter" id="filter" class="col-md-12 divScroll" (change)="changeLanguage($event,false)" [(ngModel)]="filterType">
                <option *ngFor="let page of LanguagePageNameList" value={{page.description}}>
                    {{page.description}}
                </option>
            </select>
        </div>
        <div class="col-md-4 divTopLength" style="padding-left: 10px;padding-right: 10px;">
            <label class="lblMarginBottom">Key Search:</label>
            <form class="" [formGroup]="searchForm" (ngSubmit)="searchEvent(searchForm)">
                <div class="col-md-12 d-flex pl-0 pr-0">
                    <input class="form-control mr-3" type="search" formControlName="txtSearch" name="txtSearch" placeholder="Key Search" autocomplete="off" (input)="filterItem(searchForm)">
                    <button class="btn btn-green mr-4 mb-3 capitalizeText boldText" title="Search" (click)="searchEvent(searchForm)"> {{ "Search" | translate }}</button>
                </div>
            </form>
        </div>

        <div class="col-md-2" style="margin-top:31px;">
            <!-- <button class="btn btn-info mr-2 capitalizeText boldText" title="Missing Translations" (click)="getMissingTranslations()"> Missing Translations</button> -->
            <button *ngIf="totalRecords > 0" class="btn btn-green mr-1 capitalizeText boldText" style="margin-left: -20px;margin-right: 10px;" title="Save" (click)="onSubmit(false)">{{ "Save"
                | translate }}</button>
            <button *ngIf="totalRecords > 0" class="btn btn-green capitalizeText boldText" style="margin-left: 4px;" title="Submit" (click)="onSubmit(true)">{{
                "Submit" | translate }}</button>
            <!-- <button class="btn btn-green mr-2 capitalizeText boldText" title="Help" (click)="openHelpModalPopup()">Help</button> -->
            <img [src]="helpImage" alt="Help" title="Click here to view Help" style="width: 40px; cursor: pointer; margin-left: 4px;" (click)="openHelpModalPopup()" />
        </div>
    </div>

    <div class="row ">
        <div class="col-md-12" style="background: #e0e4ee;">
            <form [formGroup]="editForm" (ngSubmit)="onSaveTranslation(editForm.value)">
                <table class="table table-bordered table-sm m-0 cont_table table-responsive-sm">
                    <thead>
                        <tr>
                            <th style="display: none;"></th>
                            <th style="width:10%;" class="sortable lblTransaltions" [ngClass]="{'asc':(sortingName==='keyName'&&isDesc===false), 'desc':(sortingName==='keyName'&&isDesc===true)}" (click)="sort('keyName')">Key Name</th>
                            <th style="width:20%;" class="sortable lblTransaltions" [ngClass]="{'asc':(sortingName==='keyDescription'&&isDesc===false), 'desc':(sortingName==='keyDescription'&&isDesc===true)}" (click)="sort('keyDescription')">Key Description</th>
                            <th style="width:12%;" class="lblTransaltions">Page</th>
                            <th style="width:10%;" class="lblTransaltions" *ngIf="isLanguageAdmin || isLanguageTranslator">Context</th>
                            <th style="width:20%" class="lblTransaltions">Key Translation</th>
                            <th style="width:14%" class="lblTransaltions">Comments</th>
                            <th class="sortable lblTransaltions" [ngClass]="{'asc':(sortingName==='status'&&isDesc===false), 'desc':(sortingName==='status'&&isDesc===true)}" (click)="sort('status')">Status</th>
                            <th style="display: none;"></th>
                            <th style="display: none;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container formArrayName="controlArray">
                            <tr *ngFor="let rowData of  translationRows.controls| paginate: { itemsPerPage:defaultPageSize, currentPage: p, totalItems: totalRecords }; let i = index" [formGroupName]="i">

                                <td style="display: none;">{{rowData.value.id}}</td>
                                <td [ngClass]="{'sort-column':sortingName==='keyName'}">
                                    <label>{{rowData.value.keyName}}</label>
                                </td>
                                <td [ngClass]="{'sort-column':sortingName==='keyDescription'}"><label class="lblKeyDescription">{{rowData.value.keyDescription}}</label></td>
                                <td>
                                    <div *ngIf="rowData.value.status == 'Submitted'">
                                        <label>{{rowData.value.pageName}}</label>
                                    </div>
                                    <div *ngIf="rowData.value.status == 'Work In Progress' || rowData.value.status == ''">
                                        <select name="pageName" id="pageName" class="col-md-12 divScroll" formControlName="pageName">
                                            <option *ngFor="let page of LanguagePageNameList"
                                                [selected]="page.description === rowData.value.pageName"
                                                [value]="page.description">
                                                {{page.description}}
                                            </option>
                                        </select>
                                    </div>
                                </td>
                                <td style="width:10%;padding-left: 20px;padding-right: 0px;" *ngIf="isLanguageAdmin || isLanguageTranslator">
                                    <button class="btn btn-green mr-2 capitalizeText" *ngIf="isLanguageAdmin || isLanguageTranslator" type="button" style="width:73px" (click)="onView($event,i)"> {{ "View" | translate }}</button>

                                    <input accept="image/x-png,image/jpeg" (change)="onFilechange($event)" #file class="form-control" style="display: none;" type="file" [id]=rowData.value.resourceKeyId>
                                    <button class="btn btn-green capitalizeText" *ngIf="isLanguageAdmin" (click)="file.click()" type="button" style="vertical-align: top;"> {{ "Upload" |
                                        translate }}</button>
                                </td>
                                <td>
                                    <div *ngIf="rowData.value.status == 'Submitted'">
                                        <label class="lblKeyDescription">{{rowData.value.keyValue}}</label>
                                    </div>
                                    <div *ngIf="rowData.value.status == 'Work In Progress' || rowData.value.status == ''">
                                        <textarea [value]=rowData.value.keyValue style="width:100%;" formControlName="keyValue"></textarea>
                                    </div>

                                    <!-- <textarea [value]=rowData.value.keyValue style="width:100%;" formControlName="keyValue"></textarea> -->
                                </td>
                                <td style="width:14%">
                                    <div *ngIf="rowData.value.status == 'Submitted'">
                                        <label class="lblKeyDescription" style="overflow-x: hidden;">{{rowData.value.comments}}</label>
                                    </div>
                                    <div *ngIf="rowData.value.status == 'Work In Progress' || rowData.value.status == ''">
                                        <textarea [value]=rowData.value.comments style="width:100%;" formControlName="comments"></textarea>
                                    </div>

                                    <!-- <textarea [value]=rowData.value.comments style="width:100%;" formControlName="comments"></textarea> -->
                                </td>

                                <td style="width:7%;" [ngClass]="{'sort-column':sortingName==='status'}">
                                    <label>{{rowData.value.status}}</label>
                                </td>
                                <td style="display: none;">{{rowData.value.languageCode}}</td>
                                <td style="display: none;">{{rowData.value.resourceKeyId}}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>

                <table *ngIf="totalRecords == 0" class="table table-bordered table-sm m-0 table-responsive-sm">
                    <tbody>
                        <tr>
                            <td [attr.rowspan]="10" style="text-align: center;">
                                <label style="font-size: 18px;margin-bottom: 12px;margin-top: 10px;">No translations found.</label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>

            <div class="my-paginationdiv" style="margin-top: -30px;">
                <pagination-controls class="my-pagination" previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="pageChangeEvent(p = $event)"></pagination-controls>
            </div>

            <div class="col-md-2 d-flex p-0 mt-2 mb-2 pl-md-2 pr-md-2 mt-md-0 mb-md-0 divPageSize">
                <div style="margin-top: 45px;"> <label class="lblPageSize"> {{ "PageSize" | translate }}: &nbsp;</label>
                </div>
                <select [ngModel]="defaultPageSize" class="slPageSize" (change)="changePageSize($event,defaultPageSize)">
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <!-- <option value="20">20</option> -->
                </select>
            </div>

        </div>
    </div>

    <div *ngIf="samesite === 'false'">
        <app-footer></app-footer>
    </div>

</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
    <div class="modal-dialog" role="document" style="max-width: 850px;margin-top: 202px;">
        <div class="modal-content">
            <div class="modal-header" style="padding: 5px 15px 5px 15px;">
                <h5 class="modal-title capitalizeText">Document</h5>
                <input type="button" class="cancel btn" data-dismiss="dialog" (click)="closePopup()">
            </div>
            <div class="modal-body docModalBody divScroll">
                <div *ngFor="let item of imageData">
                    <div class="divModalBorder">
                        <p class="mt-2 ml-3" style="margin-bottom: 10px;">{{item.name}}</p>
                    </div>
                    <img [src]="item.Url" />
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger capitalizeText" (click)="closePopup()">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" role="dialog" data-backdrop="false" data-keyboard="false" [ngStyle]="{'display':helpModalDisplayStyle}">
    <div class="modal-dialog " role="document" style="max-width: 785px;margin-top: 236px;">
        <div class="modal-content">
            <div class="modal-header" style="padding: 5px 15px 5px 15px;">
                <h5 class="modal-title capitalizeText">Help</h5>
                <input type="button" class="cancel btn" data-dismiss="dialog" (click)="closeHelpModalPopup()">
            </div>
            <div class="modal-body helpModalBody divScroll">
                <table id="helpTable" class="table table-bordered table-sm m-0 cont_table table-responsive-sm helpTable">
                    <thead>
                        <tr style="background-color: #c9d0e3 ">
                            <th width="20%">Label</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody class="helpBody">
                        <tr>
                            <td>Language</td>
                            <td>Select the service language in which the text needs to be translated.</td>
                        </tr>
                        <tr>
                            <td>Page</td>
                            <td>Select the RSign user interface page in which the text needs to be translated.</td>
                        </tr>
                        <tr>
                            <!-- <td>Search Criteria</td> -->
                            <td>Key Search</td>
                            <td>Enter a Key description as search criteria.</td>
                        </tr>
                        <tr>
                            <td>Key Name</td>
                            <td>A unique identifier is assigned by the system to every label or text shown on the page.</td>
                        </tr>
                        <tr>
                            <td>Key Description</td>
                            <td>Description of the unique Key Name.</td>
                        </tr>
                        <tr>
                            <!-- <td>Translation</td> -->
                            <td>Key Translation</td>
                            <td>Enter the desired translation in the text field.</td>
                        </tr>
                        <tr>
                            <td>Missing Translations</td>
                            <td>Select this value to enlist all text labels that are not translated on the page.</td>
                        </tr>
                        <tr>
                            <td>View</td>
                            <td>Click view to view the uploaded images.</td>
                        </tr>
                        <tr>
                            <td>Upload</td>
                            <td>Click upload to upload screenshots of missing translations.</td>
                        </tr>
                        <tr>
                            <td>Save</td>
                            <td>Saves the translations in the database. Users can edit saved translations.</td>
                        </tr>
                        <tr>
                            <td>Submit</td>
                            <td>Commits the translations. Committed translations cannot be edited.</td>
                        </tr>
                        <tr>
                            <td>Status</td>
                            <td>Saved translations are editable after saving and appear as "Work in Progress".<br>
                                Submitted translations become read only and appear as "Submitted".</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="modal-footer" style="border-top-width: 0px;">
                <button type="button" class="btn btn-danger capitalizeText" (click)="closeHelpModalPopup()"> Close
                </button>
            </div> -->
        </div>
    </div>
</div>