import { Component, OnInit } from '@angular/core';
import { FooterRoutes } from '../shared/constants/constants'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  samesite: boolean;
  legalNotices = FooterRoutes.LegalNotices;
  technologies = FooterRoutes.Technologies;
  patented = FooterRoutes.Patented;
  constructor() { }
  ngOnInit() { }
}
