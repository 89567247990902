import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common'
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomepageComponent } from './homepage/homepage.component';
import { ErrorpageComponent } from './errorpage/errorpage.component';
import { LoadingiconComponent } from './loadingicon/loadingicon.component';
import { LoginComponent } from './login/login.component';
import { UserSessionService } from './common/user-session.service';
import { ToasterService } from './common/toasterservice'
import { TranslationLoaderService } from './common/translation-loader.service';
import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';
import { TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { LogService } from './service/log.service';
import { LangTranslationComponent } from './components/lang-translation/lang-translation.component';
import { WebhookComponent } from './components/webhook/webhook.component';
import { TokenGuardService } from './shared/guards/token-guard';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,  
    HomepageComponent,
    ErrorpageComponent,
    LoadingiconComponent,
    LoginComponent,
    LangTranslationComponent,
    WebhookComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (http: HttpClient) => {
            return new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
          },
          deps: [HttpClient]
      }
  }),   
    ToastrModule.forRoot({       
      timeOut: 2000,     
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true,      
      // progressBar:true, 
      autoDismiss: false, 
      newestOnTop: true
    }),
  ],
  providers: [UserSessionService, ToasterService, TranslationLoaderService, LogService, { provide: LocationStrategy, useClass: HashLocationStrategy },TokenGuardService],
  exports: [TranslateModule ],
  bootstrap: [AppComponent]
})
export class AppModule { }
